<template>
    <div>
        <CheckoutHeader :back-button-action="backBtnAction" class="-mb-4">
            <template v-slot:back>
                {{ $t("checkout.back_recurrence") }}
            </template>
            <template v-slot:header>
                <template>
                    <span data-cy="choose-wizard_control-text">
                        2 {{ $t("checkout.of") }} 4 &mdash;
                    </span>
                </template>
                <strong data-cy="choose-wizard_control_choose-text">
                    {{ $t("checkout.chose_recurrence") }}</strong
                >
            </template>
            <template v-slot:sub-header>
                {{ $t("plan") }}
                <strong data-cy="recurrence-plan-text">{{
                    getDefaultPlanName
                }}</strong>
            </template>
        </CheckoutHeader>
        <div class="flex flex-wrap">
            <div v-if="getPlanRecurrencies" class="inner-container">
                <div
                    v-for="recurrence in getPlanRecurrencies"
                    :key="recurrence.id"
                    @click="gtmEvent(recurrence.recurrencePeriod)"
                >
                    <list-item
                        :value="recurrence.name"
                        :line1="line1(recurrence)"
                        :line2="
                            line2(recurrence.recurrencePeriod, recurrence.price)
                        "
                        :data-cy="`choose-${recurrence.recurrencePeriod}-list`"
                        @click.native="setRecurrence(recurrence)"
                        :selected="
                            recurrence.recurrencePeriod === selectedRecurrence
                        "
                    />
                </div>
            </div>
        </div>
        <div class="advance-btn justify-center align-middle">
            <submit-button-default
                style="height: 73px; max-width: 100%;"
                :text="$t('advance')"
                data-cy="checkout-submit1-btn"
                :disabled="!isRecurrenceSelected"
                @click.native="nextStep(); eventGtm('custom.nextRecurrence')"
            />
        </div>
    </div>
</template>

<script>
import {
    PAYMENT_METHOD_SELECTOR_VIEW,
    PLAN_SELECTOR_VIEW,
    RECURRENCE_SELECTOR_VIEW,
} from "@/router/constants";
import {
    CHECKOUT_MODULE,
    GET_RECURRENCE,
    CHANGE_RECURRENCE,
    GET_PLAN,
    GET_PLAN_SELECTED_RECURRENCIES,
    CHANGE_CHOSEN_PLAN,
    GET_PLANS,
} from "../store/constants/checkout";
import { mapGetters, mapActions } from "vuex";
import CheckoutHeader from "@/components/Checkout/Header.vue";
import ListItem from "@/components/Checkout/ListItem.vue";
import SubmitButtonDefault from "@/components/SubmitButtonDefault.vue";
import Utils from "../utils/Utils";

export default {
    name: RECURRENCE_SELECTOR_VIEW,
    components: {
        CheckoutHeader,
        ListItem,
        SubmitButtonDefault,
    },
    data() {
        return {
            selectedRecurrence: null,
        };
    },
    computed: {
        ...mapGetters({
            getRecurrence: `${CHECKOUT_MODULE}/${GET_RECURRENCE}`,
            getPlan: `${CHECKOUT_MODULE}/${GET_PLAN}`,
            getPlanRecurrencies: `${CHECKOUT_MODULE}/${GET_PLAN_SELECTED_RECURRENCIES}`,
            getAllPlansAction: `${CHECKOUT_MODULE}/${GET_PLANS}`,
            eventGtm: 'eventGtm'
        }),
        isRecurrenceSelected() {
            return this.getRecurrence?.name;
        },
        getDefaultPlanName() {
            return this.$t("plan_default_name")[this.getPlan?.name];
        },
    },
    async mounted() {
            const arrayPlans = Utils.observerToArray(this.getAllPlans);
            const planRecurrencies = arrayPlans.filter((plan) => {
                return plan.name?.includes(this.selectedPlan);
            });
            await this.changePlanRecurrencies(planRecurrencies.reverse());
    },
    methods: {
        ...mapActions({
            changeRecurrenceAction: `${CHECKOUT_MODULE}/${CHANGE_RECURRENCE}`,
            changeChosenPlanAction: `${CHECKOUT_MODULE}/${CHANGE_CHOSEN_PLAN}`,
        }),
        backBtnAction() {
            return this.$router.push({
                name: PLAN_SELECTOR_VIEW,
            });
        },
        nextStep() {
            this.changeChosenPlanAction(this.getRecurrence);
            return this.$router.push({
                name: PAYMENT_METHOD_SELECTOR_VIEW,
            });
        },
        line1(recurrence) {
            return `<strong>${
                this.$t("recurrences_num")[recurrence.recurrencePeriod]
            }</strong> &mdash; ${this.$t("currency.sign")}${
                recurrence.price
            }  <small class='text-cool-gray-500'>${
                this.$t("recurrences_freq_num")[recurrence.recurrencePeriod]
            }</small>`;
        },
        line2(recurrence, price) {
            const discount = Utils.calcValuePerMounth(recurrence, price);
            if (parseInt(discount) === parseInt(price)) {
                return `<em>${this.$t("checkout.no_discount")}</em>`;
            }
            return `<em>${this.$t("checkout.pre_discount")} ${this.$t("currency.sign")} ${discount} /${this.$t("month")}</em>`;
        },
        setRecurrence(recurrence) {
            this.changeRecurrenceAction(recurrence);
            this.selectedRecurrence = recurrence.recurrencePeriod;
        },
        gtmEvent(recurrence) {
            const labels = {
                12: 'anual',
                6: 'semestral',
                1: 'mensal'
            }
            this.eventGtm(labels[recurrence])
        }
    },
};
</script>

<style lang="scss">
.submit-label {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}
.advance-btn {
    width: 100%;
    max-width: 512px;
    margin-left: auto;
    margin-right: auto;
}
</style>
