<template>
    <div>
        <svg
            viewBox="0 0 512 512"
            class="h-5 mr-4 -mt-1 inline-block"
            :fill="color"
        >
            <circle
                v-show="selected"
                cx="256"
                cy="256"
                r="137.2"
                fill="#0099f9"
            />
            <path
                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256
        8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5
        200-200 200z"
            />
        </svg>
    </div>
</template>

<script>
export default {
    name: "RadioButton",
    props: {
        value: {
            type: String,
            default: null,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    computed: {
        color() {
            return this.disabled ? "#ACACAC" : "#000000";
        },
    },
};
</script>

<style></style>
