var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "list-items flex",
      class: { active: _vm.selected, disabled: _vm.disabled },
    },
    [
      _c("RadioButton", {
        attrs: {
          disabled: _vm.disabled,
          selected: _vm.selected,
          value: _vm.value,
        },
      }),
      _c("div", { staticClass: "grid grid-rows-2 gap-0" }, [
        _c("div", { staticClass: "flex" }, [
          _c("div", { domProps: { innerHTML: _vm._s(_vm.line1) } }),
        ]),
        _c("div", { staticClass: "flex" }, [
          _c("div", { domProps: { innerHTML: _vm._s(_vm.line2) } }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }