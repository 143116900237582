var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "svg",
      {
        staticClass: "h-5 mr-4 -mt-1 inline-block",
        attrs: { viewBox: "0 0 512 512", fill: _vm.color },
      },
      [
        _c("circle", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.selected,
              expression: "selected",
            },
          ],
          attrs: { cx: "256", cy: "256", r: "137.2", fill: "#0099f9" },
        }),
        _c("path", {
          attrs: {
            d: "M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256\n    8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5\n    200-200 200z",
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }