export default class Utils {
    static calcDiscount(months, valuePerMonth, comparingValue) {
        const totalPerMonth = (months * valuePerMonth)
        return totalPerMonth - comparingValue
    }
    static calcValuePerMounth(months, total) {
        return (total / months).toFixed(0)
    }
    static observerToArray(object) {
        return Object.values(
            JSON.parse(JSON.stringify(object))
        )
    }

}
