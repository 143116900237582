<template>
    <div
        class="list-items flex"
        :class="{ active: selected, disabled: disabled }"
    >
        <RadioButton :disabled="disabled" :selected="selected" :value="value" />
        <div class="grid grid-rows-2 gap-0">
            <div class="flex">
                <div class="" v-html="line1" />
            </div>
            <div class="flex">
                <div class="" v-html="line2" />
            </div>
        </div>
    </div>
</template>

<script>
import RadioButton from "../Form/BaseRadio.vue";

export default {
    name: "ListItem",
    components: {
        RadioButton,
    },
    computed: {},
    props: {
        line1: {
            type: String,
            required: true,
            default: null,
        },
        line2: {
            type: String,
            required: true,
            default: null,
        },
        value: {
            type: String,
            required: true,
            default: null,
        },
        selected: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.list-items {
    height: 75px;
    display: flex;
    align-items: center;
    @apply p-5 border-2 mb-3 w-full cursor-pointer rounded-lg border-cool-gray-200 bg-white;
    &:hover {
        @apply border-cool-gray-600 shadow;
    }
    &.active {
        @apply border-black shadow;
        &:hover {
            @apply cursor-default;
        }
    }
    &.disabled {
        background: #fafafa !important;
        color: #acacac;

        @apply border-none cursor-not-allowed;
        &:hover {
            @apply shadow-none border-none;
        }
    }
}
</style>
