var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("CheckoutHeader", {
        staticClass: "-mb-4",
        attrs: { "back-button-action": _vm.backBtnAction },
        scopedSlots: _vm._u([
          {
            key: "back",
            fn: function () {
              return [
                _vm._v(" " + _vm._s(_vm.$t("checkout.back_recurrence")) + " "),
              ]
            },
            proxy: true,
          },
          {
            key: "header",
            fn: function () {
              return [
                [
                  _c(
                    "span",
                    { attrs: { "data-cy": "choose-wizard_control-text" } },
                    [_vm._v(" 2 " + _vm._s(_vm.$t("checkout.of")) + " 4 — ")]
                  ),
                ],
                _c(
                  "strong",
                  { attrs: { "data-cy": "choose-wizard_control_choose-text" } },
                  [_vm._v(" " + _vm._s(_vm.$t("checkout.chose_recurrence")))]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "sub-header",
            fn: function () {
              return [
                _vm._v(" " + _vm._s(_vm.$t("plan")) + " "),
                _c("strong", { attrs: { "data-cy": "recurrence-plan-text" } }, [
                  _vm._v(_vm._s(_vm.getDefaultPlanName)),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", { staticClass: "flex flex-wrap" }, [
        _vm.getPlanRecurrencies
          ? _c(
              "div",
              { staticClass: "inner-container" },
              _vm._l(_vm.getPlanRecurrencies, function (recurrence) {
                return _c(
                  "div",
                  {
                    key: recurrence.id,
                    on: {
                      click: function ($event) {
                        return _vm.gtmEvent(recurrence.recurrencePeriod)
                      },
                    },
                  },
                  [
                    _c("list-item", {
                      attrs: {
                        value: recurrence.name,
                        line1: _vm.line1(recurrence),
                        line2: _vm.line2(
                          recurrence.recurrencePeriod,
                          recurrence.price
                        ),
                        "data-cy": `choose-${recurrence.recurrencePeriod}-list`,
                        selected:
                          recurrence.recurrencePeriod ===
                          _vm.selectedRecurrence,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.setRecurrence(recurrence)
                        },
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "advance-btn justify-center align-middle" },
        [
          _c("submit-button-default", {
            staticStyle: { height: "73px", "max-width": "100%" },
            attrs: {
              text: _vm.$t("advance"),
              "data-cy": "checkout-submit1-btn",
              disabled: !_vm.isRecurrenceSelected,
            },
            nativeOn: {
              click: function ($event) {
                _vm.nextStep()
                _vm.eventGtm("custom.nextRecurrence")
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }